import React, { useContext } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { AngleDownIcon } from "../../../shared/icons";
import { ANGLE_ICON_COLOR } from "../../../../helpers/constants";
import { useModal } from "../../../../helpers/hooks/use-modal";
import LanguageContext from "../../../../context/language-context";
import Popup from "../../../shared/popup";
import LangOptions from "../lang-options";

const LangSelect = ({ className, isHeader = false, setIsLangPopupOpened }) => {
  const {
    selectedLanguage,
    selectedLanguage: { icon: Icon } = {},
    setSelectedLanguage,
  } = useContext(LanguageContext);
  const { isShow, handleOpen, handleClose } = useModal();

  const closePopup = () => {
    setIsLangPopupOpened?.(false);
    handleClose();
  };

  const onLangSelect = (selected) => {
    setSelectedLanguage(selected);
    closePopup();
  };

  const setIconColor = (isShow) => {
    if (isHeader) {
      return isShow ? ANGLE_ICON_COLOR.white : ANGLE_ICON_COLOR.white;
    }

    return isShow ? ANGLE_ICON_COLOR.white : ANGLE_ICON_COLOR.red;
  };

  return (
    <>
      <button
        className={cn(
          "lang-select",
          { "lang-select--active": isShow },
          className
        )}
        type="button"
        onClick={() => {
          handleOpen();
          setIsLangPopupOpened?.(true);
        }}
      >
        {Icon && <Icon className="lang-select__flag" />}

        {isHeader && (
          <span className="lang-select__title">{selectedLanguage.id}</span>
        )}

        <AngleDownIcon
          className={cn("lang-select__icon", {
            "lang-select__icon--up": isShow,
          })}
          color={setIconColor(isShow)}
        />
      </button>

      <Popup isPopupOpen={isShow} handlePopupClose={closePopup}>
        <LangOptions
          selectedLanguage={selectedLanguage}
          languageSelectHandler={onLangSelect}
        />
      </Popup>
    </>
  );
};

LangSelect.propTypes = {
  className: PropTypes.string,
  isHeader: PropTypes.bool,
  setIsLangPopupOpened: PropTypes.func,
};
export default LangSelect;
